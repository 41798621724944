import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { MDXProvider } from '@mdx-js/react';
// import { LinkToStacked } from 'react-stacked-pages-hook';

import scrollTo from 'gatsby-plugin-smoothscroll';

import components from './MdxComponents';
import useWindowWidth from '../../utils/useWindowWidth';
import SEO from "../components/seo";

import styled from 'styled-components';
import {ArrowIosUpwardOutline} from '@styled-icons/evaicons-outline/ArrowIosUpwardOutline';
const ScrollToTopIcon = styled(ArrowIosUpwardOutline)`
  width: 25px !important;
`;

const footerItemsStyles = `text-gray-400 rounded-md hover:bg-gray-100 transform transition duration-500`;

// const NOTE_WIDTH = 650; // 576;
const NOTE_MAX_WIDTH = 800; // 768;

const BrainNote = ({ note }) => {

  // graphql query
  const data = useStaticQuery(query)

  const [width] = useWindowWidth();

  // get css selector to scroll up to
  const scrollToTarget = `#note-container-${note.slug.replace('/', '')}`;

  // put scroll button before refs / contact text
  let referenceBlock;
  referenceBlock = (
    <div>
      <div className='mb-2 text-center'>
        <button className={`py-0 px-4 ${footerItemsStyles}`} onClick={() => scrollTo(scrollToTarget)} title='Scroll to top'><ScrollToTopIcon/></button>
      </div>
    </div>
  );

  return (
    <MDXProvider components={{ ...components, }}>
      <SEO title={note.title} description={note.excerpt} />

      <div className="flex-1 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-20 xs:pt-20">
        <MDXRenderer>{note.childMdx.body}</MDXRenderer>
      </div>

      <div>
        <div className="refs-box p-6 pt-4 pb-2 mb-2">
          {referenceBlock}
          {note.slug === 'about' ?
            <p className="pb-2 text-center font-serif">
            “Truly the light is sweet, and it is pleasant for the eyes to behold the sun...”<br/>
            ― Ecclesiastes 11:7 (NKJV)
            </p> : ``}
        </div>
      </div>
    </MDXProvider>
  );
};

// Site last updated: {data.site.buildTime}

export const query = graphql`
  query {
    site {
      buildTime(fromNow: true)
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    allFile (filter: { extension: { eq: "mdx" } }) {
      nodes {
        name
      }
    }
  }
`

export default BrainNote;
