import React from 'react';
// import Tippy from '@tippyjs/react';
// import {inlinePositioning} from 'tippy.js';
import 'tippy.js/animations/shift-away.css';
// import { LinkToStacked } from 'react-stacked-pages-hook';
// import { Link } from 'gatsby';


import Image from "./Image";
import ZoomableImage from "./ZoomableImage";

const TimelineItem = ({year, item, description, ...rest}) => {
  return (
    <>
      <div className="text-sm text-zinc-400">
        {year}
      </div>
      <div className="col-span-2 text-sm">
        {item}
        <p className='mt-2 text-zinc-400 text-left'>{description}</p>
      </div>
    </>
  )
};

const TimelineItemWithLink = ({year, item, link, description, ...rest}) => {
  return (
    <>
      <div className="text-sm text-zinc-400">{year}</div>
      <div className="col-span-2 text-sm">
        <a className='hover:underline' href={link} target="_blank">{item}</a> ↗
        <p className='mt-2 text-zinc-400 text-left'>{description}</p>
      </div>
    </>
  )
};


const ExtLink = ({text, link, ...rest}) => {
  return (
    <>
      <a className='hover:underline decoration-1 transition duration-500' href={link} target="_blank" {...rest}>{text}</a> ↗
    </>
  )
};

export default {
  // a: AnchorTag,
  extlink: ExtLink,
  img: Image,
  zimg: ZoomableImage,
  tli: TimelineItem,
  tliwl: TimelineItemWithLink,
};
