import React from 'react';
import { Link } from 'gatsby';
import { useStackedPagesProvider, LinkToStacked } from 'react-stacked-pages-hook';
import { Helmet } from 'react-helmet';

import BrainNote from './BrainNote';
import '../../style.css';

import { SEO } from "../../components/seo"

const NOTE_WIDTH = 650; // 576; // w-xl
const PAD = 120;
let NUMOFPAGES = 0;

// A wrapper component to render the content of a page when stacked
const StackedPageWrapper = ({
  PageIndexProvider, ContextProvider, stackedPages,
  children, slug, title, overlay, obstructed, highlighted, i,
}) => (
  <PageIndexProvider value={i}>
    <div
      style={{
        left: PAD * i,
        right: - NOTE_WIDTH + PAD,
        width: NOTE_WIDTH
      }}

      className={
        `note-container border-opacity-50 md:max-w-2xl px-6 py-4
        text-black md:sticky flex flex-col flex-shrink-0

        ${obstructed ? `overflow-y transition ease-in-out duration-1000` : `overflow-y-auto`}

        ${overlay ? 'border-none shadow-2xl shadow-gray-200' : 'px-6 border-l border-gray-200'}`
      }
    >
      <div id = {`note-container-${slug.replace('/', '') }`}>

        <div
          className={`md:block hidden transition-opacity duration-1000 ${
            obstructed ? `group opacity-100 hover:translate-y-6 hover:transition hover:ease-in-out hover:duration-500` : `opacity-0`
          }`}
        >

          <div className={`overflow-visible
          transform flex origin-left rotate-90
          box-content w-full ml-9 py-4 pr-6
          rounded-md border-b-8 border-gray-300/50 border-solid brightness-100
          transition ease-in-out delay-1000 duration-1000 shadow-xl shadow-gray-600/30
          ${(i === 0) ? `bg-zinc-800/90` : ``}
          ${(i === 1) ? `bg-green-800/90` : ``}
          ${(i === 2) ? `bg-zinc-400/90` : ``}
          ${(i === 3) ? `bg-blue-800/90` : ``}
          uppercase tracking-widest font-normal truncate
          `}>
            <LinkToStacked to={slug} className="container group no-underline">
            <div className="flex-auto ml-10 mt-4 w-64">
                <p className={`mx-2 text-xl font-serif text-gray-800 subpixel-antialiased
                ${(i === 0) ? `text-zinc-200` : ``}
                ${(i === 1) ? `text-green-200` : ``}
                ${(i === 2) ? `text-zinc-200` : ``}
                ${(i === 3) ? `text-blue-300` : ``}
                `}
                >
                  {title.replace('Martins Dogo', 'Mar') || slug}
                </p>
            </div>
            </LinkToStacked>

            <div className="mt-4 px-20">
              <p  className={`text-xl font-serif
                ${(i === 0) ? `text-zinc-200` : ``}
                ${(i === 1) ? `text-green-200` : ``}
                ${(i === 2) ? `text-zinc-200` : ``}
                ${(i === 3) ? `text-blue-200` : ``}`}>
                 ✵
              </p>
            </div>

          </div>

        </div>

      </div>
      <div
        className={`flex flex-col min-h-full transition-opacity duration-100 ${
          obstructed ? `opacity-0` : `opacity-100`
        }`}
      >
        {children}
      </div>
    </div>
  </PageIndexProvider>
);

const BrainNotesContainer = ({ slug, note, location, siteMetadata }) => {
  // process data from gatsby pageQuery API
  const processPageQuery = React.useCallback((x) => x.json.data.brainNote, []);
  const [
    stackedPages,
    stackedPageStates,
    navigateToStackedPage,
    ContextProvider,
    PageIndexProvider,
    scrollContainer,
  ] = useStackedPagesProvider({
    firstPageSlug: slug,
    location,
    processPageQuery,
    pageWidth: NOTE_WIDTH,
  });

  NUMOFPAGES = stackedPages.length + 1;

  return (
    <div className="text-black flex flex-col min-h-screen h-screen">
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name="google-site-verification" content="d7sxBTz_kZBxy8y_QBUSV0CqvxG8Jjg__Rganh3dlbU"/>
        <title>
          {note.title} | {siteMetadata.title}
        </title>
      </Helmet>

      <header className='justify-between'>
        <div className="py-6">
          <Link to="/" className="no-underline text-xl">
            <h2 className="h-8 max-h-8 text-2xl antialiased tracking-tight font-medium">
              Mar {/* {siteMetadata.title} */}
            </h2>
          </Link>
        </div>

        <div className='relative right-0 w-8 h-8'>
          <div className='absolute w-8 h-8 bg-gradient-to-r from-orange-500 to-amber-400 rounded-full blur-sm animate-blob'></div>
        </div>
      </header>

      <div
        className= "transform origin-right flex flex-grow overflow-x-hidden md:overflow-x-auto overflow-y-hidden"
        ref={scrollContainer}
      >

        <div
          className="note-columns-container flex flex-grow transition-width duration-1000"
          style={{ width: NOTE_WIDTH * (stackedPages.length + 1) }}
        >

          <ContextProvider value={{ stackedPages, navigateToStackedPage }}>
            {/* Render the first page */}
            <StackedPageWrapper
              PageIndexProvider={PageIndexProvider}
              i={0}
              slug={slug}
              title={note.title}
              overlay={stackedPageStates[slug] && stackedPageStates[slug].overlay}
              obstructed={stackedPageStates[slug] && stackedPageStates[slug].obstructed}
              highlighted={stackedPageStates[slug] && stackedPageStates[slug].highlighted}
            >
              <BrainNote note={note} />
            </StackedPageWrapper>

            {/* Render the stacked pages */}
            {stackedPages.map((page, i) => (
              <StackedPageWrapper
                PageIndexProvider={PageIndexProvider}
                i={i + 1}
                NUMOFPAGES = {i}
                key={page.slug}
                slug={page.slug}
                title={page.data.title}
                overlay={stackedPageStates[page.slug] && stackedPageStates[page.slug].overlay}
                obstructed={stackedPageStates[page.slug] && stackedPageStates[page.slug].obstructed}
                highlighted={stackedPageStates[slug] && stackedPageStates[slug].highlighted}
              >
                <BrainNote note={page.data} />
              </StackedPageWrapper>
            ))}
          </ContextProvider>
        </div>
      </div>
    </div>
  );
};

export default BrainNotesContainer;
export const Head = () => (
  <SEO>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Person",
          "image": "https://s3-eu-west-1.amazonaws.com/tutors.firsttutors.com/148/147081/lrg.png"
          "url": "https://msdogo.com",
          "sameAs": ["https://www.msdogo.com", "https://martinssamuel.com"],
          
          "name": "Martins Dogo",
          "alternateName": ["Martins Samuel Dogo", "Martins Samuel"],
          "description": "Hi, I'm Martins. I'm a technology consultant and designer based in Belfast.",
          
          "email": "samartins@pm.me",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "samartins@pm.me",
            "contactType": "Email"
          }
        }
      `}
    </script>
  </SEO>
)